import { SvgIcon } from '../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

export const CoffeeIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ className = '', ...restProps }) => {
	return (
		<SvgIcon className={`coffee-icon ${className}`} height={60} width={60} {...restProps}>
			<path
				d='m46.331 17.517-.524-.12-.225-.488c-1.144-2.499-3.09-4.289-5.478-5.041-1.662-.524-3.544-.79-5.594-.79-5.296 0-11.735 1.781-17.667 4.885l-.092.05-3.479 2.039-.092.06c-2.684 1.757-4.878 3.642-6.52 5.597-2.32 2.762-2.662 6.415-.894 9.535 1.143 2.02 3.772 5.901 6.585 9.41 5.226 6.52 7.971 8.03 9.78 8.617 1.247.405 2.659.609 4.196.609 5.928 0 14.7-3.27 19.28-9.332.47-.619.868-1.33 1.194-2.113l.158-.377.366-.182c4.047-1.999 6.464-5.133 7.186-9.312.603-3.49-.304-6.986-2.493-9.584a10.34 10.34 0 0 0-5.681-3.463h-.006Z'
				fill='#F89143'
			/>
			<path
				d='M.48 52.162c-.16-.2-.32-.404-.48-.605v3.446c1.632 1.95 3.156 3.598 4.587 4.994h3.188c-2.159-1.906-4.57-4.432-7.294-7.837v.002Z'
				fill='#00AAE8'
			/>
			<path
				d='m51.953 10.293-.258-.12-.168-.23C49.3 6.893 46.253 4.69 42.71 3.577c-2.51-.79-5.268-1.19-8.204-1.19-6.656 0-14.562 2.14-21.691 5.874l-.114.06a7.05 7.05 0 0 0-.32.177l-3.526 2.064a9.14 9.14 0 0 0-.328.203l-.112.074c-3.392 2.224-6.222 4.674-8.41 7.278L0 18.122v3.816a15.381 15.381 0 0 1 1.667-2.422c2.054-2.444 4.723-4.75 7.941-6.858l.115-.073c.076-.052.154-.098.23-.144l3.526-2.064c.073-.043.149-.087.228-.128l.114-.06c6.83-3.576 14.369-5.626 20.686-5.626 2.713 0 5.252.366 7.55 1.089 3.375 1.061 6.203 3.229 8.199 6.281a17.047 17.047 0 0 1 6.738 4.85 17.775 17.775 0 0 1 3 5.095V17.17a19.303 19.303 0 0 0-8.044-6.874l.003-.002Z'
				fill='#89C947'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='m48.81 13.704.349.13h-.006c2.4.894 4.535 2.396 6.179 4.346 3.017 3.582 4.274 8.364 3.451 13.122-.871 5.049-3.718 9.117-8.229 11.76l-.26.152-.144.266a13.293 13.293 0 0 1-1.081 1.684c-5.48 7.259-15.591 11.058-22.745 11.058-1.993 0-3.856-.277-5.535-.82-2.533-.823-5.92-2.656-11.83-10.035-3.093-3.859-5.782-7.88-6.974-9.983C-.66 30.716-.13 25.037 3.332 20.912c1.918-2.282 4.43-4.446 7.469-6.437l.111-.073c.046-.03.092-.06.141-.087l3.528-2.067c.046-.027.09-.051.136-.076l.114-.06c6.529-3.419 13.704-5.38 19.679-5.38 2.49 0 4.812.332 6.898.989 2.987.94 5.48 2.9 7.207 5.668l.196.315Zm-.167 27.934c4.426-2.305 7.188-5.999 8.003-10.705h.005c.712-4.123-.372-8.262-2.973-11.352a12.504 12.504 0 0 0-6.347-4.055c-1.429-2.816-3.745-4.842-6.57-5.73-1.874-.59-3.976-.888-6.246-.888-5.635 0-12.444 1.871-18.674 5.133l-3.685 2.15-.16.104c-2.86 1.874-5.215 3.897-6.994 6.015-2.919 3.474-3.348 8.074-1.121 12.004 1.187 2.094 3.897 6.097 6.778 9.695 5.584 6.972 8.707 8.648 10.804 9.326 1.461.473 3.099.715 4.864.715 6.453 0 16.012-3.574 21.012-10.195.5-.666.94-1.41 1.304-2.216Z'
				fill='#D3A444'
			/>
			<path
				d='M55.926 4.986c1.431.752 2.8 1.651 4.071 2.664V4.945c-.809-.57-1.643-1.1-2.504-1.577l-.168-.095-.125-.147A26.087 26.087 0 0 0 54.003.003h-3.688a23.88 23.88 0 0 1 5.611 4.986v-.003Z'
				fill='#00AAE8'
			/>
			<path
				d='M55.943 59.997h3.174c.3-.296.592-.597.88-.899V55.84A39.554 39.554 0 0 1 55.943 60v-.003Zm2.775-58.438c.432.244.858.502 1.28.768V0h-2.752c.508.5 1.002 1.018 1.475 1.559h-.003Z'
				fill='#2C8DD6'
			/>
			<path
				d='M3.87 49.444A106.672 106.672 0 0 1 0 44.289v3.696c.695.932 1.423 1.877 2.175 2.817 3.536 4.413 6.488 7.275 9.022 9.195h3.922C12.22 58.354 8.63 55.383 3.87 49.444Zm52.135.958c.188-.25.375-.508.554-.768l.111-.163.163-.114a26.132 26.132 0 0 0 3.164-2.594v-3.158a23.72 23.72 0 0 1-4.902 4.304c-.26.408-.54.804-.825 1.184-3.854 5.103-9.275 8.69-14.785 10.904h5.122c4.606-2.498 8.508-5.773 11.395-9.597l.003.002Z'
				fill='#5BBF7D'
			/>
			<path
				d='m59.473 53.018.226-.302.097-.136.136-.1.065-.049v-2.765c-.567.47-1.148.927-1.76 1.358a24.99 24.99 0 0 1-.497.685c-2.381 3.155-5.407 5.955-8.918 8.288h3.72c2.657-2.066 4.992-4.41 6.931-6.98ZM3.77 3.498l-.115.076A54.776 54.776 0 0 0 0 6.184v2.794a50.614 50.614 0 0 1 4.848-3.59l.114-.073c.209-.136.404-.258.605-.375L9.09 2.876c.193-.114.394-.225.597-.331l.109-.057A61.393 61.393 0 0 1 15.18 0H9.904c-.372.187-.746.372-1.116.565l-.103.054c-.23.122-.464.25-.692.383L4.47 3.066a18.9 18.9 0 0 0-.698.432H3.77Z'
				fill='#00AAE8'
			/>
			<path
				d='M0 58.33v1.667h1.534A69.544 69.544 0 0 1 0 58.33ZM2.466 1.754l.117-.076c.269-.176.527-.334.787-.488L5.402 0h-4.22C.781.263.387.53 0 .798v2.665a58.014 58.014 0 0 1 2.466-1.709Z'
				fill='#2C8DD6'
			/>
			<path
				d='M52.537 47.784c.296-.393.581-.806.85-1.23l.128-.204.2-.13c2.591-1.684 4.702-3.786 6.285-6.227v-4.85c-1.475 4.013-4.218 7.305-8.085 9.643-.337.595-.709 1.16-1.108 1.69-5.91 7.826-16.789 11.922-24.48 11.922-2.222 0-4.307-.313-6.206-.926-2.84-.921-6.585-2.917-12.856-10.744-3.167-3.954-5.937-8.096-7.17-10.271C.062 36.4.033 36.34 0 36.283v4.15a102.655 102.655 0 0 0 5.567 7.653c6.627 8.272 10.736 10.431 13.88 11.45.544.176 1.103.328 1.671.461h10.806c7.164-1.385 15.415-5.33 20.61-12.213h.003Z'
				fill='#89C947'
			/>
			<path
				d='M10.692 4.47c-.174.09-.345.185-.508.28L6.662 6.814c-.171.1-.337.204-.513.318l-.112.073C3.805 8.669 1.787 10.228 0 11.871v3.025c2.034-2.086 4.454-4.055 7.227-5.869l.114-.073c.141-.092.28-.18.418-.26l3.522-2.065c.133-.079.272-.155.413-.228l.114-.06C19.241 2.447 27.514.217 34.51.217c3.155 0 6.135.435 8.856 1.29 3.897 1.228 7.262 3.62 9.75 6.931A21.528 21.528 0 0 1 60 13.622v-3.104a23.884 23.884 0 0 0-5.269-3.704l-.203-.106-.144-.18A21.666 21.666 0 0 0 45.598 0H21.786a59.366 59.366 0 0 0-10.983 4.413l-.11.057Z'
				fill='#5BBF7D'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M26.393 49.718c-1.297 0-2.513-.166-3.597-.516-5.393-1.749-14.095-15.179-15.143-17.03-1.309-2.312-1.054-5.02.668-7.067 1.507-1.793 3.542-3.534 6.048-5.177l.093-.056 3.386-1.983c9.451-4.948 17.354-5.285 21.599-3.949 2.325.73 4.027 2.724 4.725 5.5 2.059.035 3.99.8 5.497 2.224 2.243 2.115 3.248 5.304 2.692 8.53-.837 4.837-4.245 7.04-7.181 8.245-.301 1.1-.742 2.04-1.315 2.798-3.725 4.934-11.45 8.48-17.472 8.481Zm-9.685-26.126c-2.102 1.382-3.849 2.87-5.057 4.307-.389.462-.739 1.203-.215 2.13 2.561 4.521 9.9 14.13 12.702 15.04 4.625 1.499 13.223-2.426 16.262-6.45.348-.46.614-1.203.769-2.146l.203-1.23-.057-2.39a78.293 78.293 0 0 0-.472-6.722L40.6 23.97l-.312-1.801a41.06 41.06 0 0 0-.266-1.415c-.139-.668-.6-2.268-1.874-2.67-4.212-1.325-11.537.134-18.234 3.631l-3.205 1.877h-.002Zm27.081.193c1.062.15 2.05.5 2.885 1.021-.582-.543-1.521-1.1-2.885-1.021Z'
				fill='#000'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M26.39 49.718c-1.297 0-2.513-.166-3.596-.516C17.4 47.453 8.699 34.023 7.65 32.172c-1.309-2.312-1.053-5.02.668-7.067 1.508-1.793 3.542-3.536 6.049-5.177l.092-.056 3.387-1.983c9.45-4.948 17.356-5.285 21.598-3.949 2.325.73 4.028 2.724 4.726 5.5a8.134 8.134 0 0 1 5.496 2.224c2.244 2.115 3.248 5.304 2.692 8.53-.837 4.837-4.245 7.04-7.18 8.245-.302 1.1-.742 2.04-1.315 2.798-3.726 4.934-11.452 8.48-17.472 8.481Zm-9.688-26.126c-2.102 1.382-3.848 2.87-5.057 4.307-.388.462-.738 1.203-.214 2.13 2.56 4.521 9.899 14.13 12.702 15.04 4.627 1.499 13.223-2.426 16.262-6.45.347-.46.613-1.203.768-2.146l.204-1.236-.057-2.387a78.338 78.338 0 0 0-.473-6.721l-.241-2.157-.313-1.806a43.46 43.46 0 0 0-.266-1.415c-.138-.668-.597-2.267-1.874-2.667-4.212-1.325-11.536.134-18.233 3.631l-3.205 1.877h-.003Zm27.082.193c1.062.15 2.05.5 2.884 1.021-.581-.54-1.518-1.1-2.884-1.021Z'
				fill='#000'
			/>
			<path
				d='M43.499 34.336c-.006 1.493-.171 4.003-1.369 5.592-4.174 5.526-13.81 8.783-18.666 7.207-4.013-1.3-11.949-12.555-13.92-16.034-1.006-1.776-.5-3.479.44-4.598 1.403-1.67 3.337-3.291 5.575-4.755l3.297-1.93c6.632-3.474 14.714-5.446 19.94-3.803 2.071.652 3.01 2.68 3.348 4.3.795 3.842 1.374 9.637 1.355 14.02Z'
				fill='#fff'
			/>
			<path
				d='M41.123 37.502c1.333.182 1.757.492 1.84.76.267-.814.403-1.68.47-2.47l-2.313 1.71h.003Zm2.112-9.155a83.199 83.199 0 0 0-.423-3.973c-.535.07-1.34.46-2.27 1.836.71.307 1.843.274 2.693 2.14v-.003Z'
				fill='#000'
			/>
			<path
				d='M41.579 22.041c5.483-1.92 9.505 2.784 8.639 7.781-.76 4.4-4.069 6.317-9.096 7.68-.695.188-1.192-3.443 1.252-4.285 2.198-.758 3.553-1.573 4.85-3.142.153-4.017-4.293-4.715-6.422-3.895-1.44.557-1.5-3.153.774-4.139h.003Z'
				fill='#fff'
			/>
			<path
				d='M12.175 27.022c-1.58 1.72-.62 3.221.864 3.536 7.118 1.969 21.533-3.699 25.631-9.929.904-1.591-.736-3.134-3.506-3.11-7.653.071-17.446 3.477-22.986 9.503h-.003Z'
				fill='url(#coffee-icon-gradient)'
			/>
			<path
				d='M34.29 24.839c-.348-.044-2.303-.253-4.734.217-.611-.489-1.14-.991-2.102-.913-1.152.093-1.6 1.584-2.333 1.07-1.255-.88-.771-2.077-1.69-3.364-.768-1.079-2.63-.81-3.128.82-.282.918-.912 1.463-1.95.337-1.865-2.024-1.22-5.272 1.095-8.797 2.047-3.115-.704-5.432-2.844-2.368-.877 1.255-1.463 1.233-2.164 1.295-.88.079-1.668.888-2.143 2.844-.668 2.748-2.17 9.597 2.056 14.841 5.59.78 14.299-2.047 19.937-5.985v.003Z'
				fill='#000'
			/>
			<path d='M26.324 20.157c.098-3.656 5.42-3.854 3.55.16-1.681 3.61-3.623 2.536-3.55-.16Z' fill='#000' />
			<path
				d='M19.032 11.097c-.926-.06-1.95 1.27-2.36 1.996-.407.725-1.616.323-2.572.904-.977.595-2.303 5.434-1.92 8.99.063.591.527.385.527-.525 0-1.667.084-2.819.695-4.59.66-1.914 1.054-2.191 1.768-2.305.948-.152 1.388-1.065 1.863-1.882.4-.687.777-1.383 1.472-1.608 1.046-.342 1.12-.94.527-.978v-.002Zm3.172 10.705c-.942.052-1.262.685-1.529 1.776-.228.94.18 2.744-.92 3.585-.633.486.125.636.567.258 1.29-1.1.924-3.633 2.013-3.856 1.292-.264.66-1.806-.133-1.763h.002Zm6.266-4.016c-.913.19-1.912 1.086-1.814 2.547.043.684.678.5.77-.318.082-.711.357-1.002 1.003-1.374.717-.413.97-1.05.043-.858l-.002.003Zm-.834 6.854c-.6.066-1.138.41-1.586 1.081-.546.82-1.426.91-1.716 1.03-.291.119-.427.6-.112.559.35-.046.932-.138 1.703-1.013.323-.367.842-.793 1.252-.888.53-.125.986-.823.459-.766v-.003Z'
				fill='#666'
			/>
			<defs>
				<linearGradient
					id='coffee-icon-gradient'
					x1='13.645'
					y1='22.221'
					x2='14.655'
					y2='32.504'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#D1D3D4' />
					<stop offset='1' stopColor='#999' />
				</linearGradient>
			</defs>
		</SvgIcon>
	);
};
