import { action, runInAction } from 'mobx';
import { AttachmentsToBeUploadedViewModel, INote, NoteViewModel, UserSessionContext } from '../../extViewmodels';

/** A light wrapper around the NoteViewModel for use in COFFEE */
export class AidaNoteViewModel extends NoteViewModel {
	constructor(userSession: UserSessionContext, note?: INote) {
		super(userSession, note);
	}

	/** Save a note associated with a lead. Also handles outcome saving in most cases */
	@action
	public saveLeadNote = async (
		note: INote,
		url: string,
		newAttachments?: AttachmentsToBeUploadedViewModel,
		isForOutcome?: boolean,
		companyId?: string
	) => {
		if (this.saving) {
			return;
		}

		this.saving = true;
		const updateExistingNote = !!this.note && !!this.note.id;
		const noteToSave = { ...note };
		if (updateExistingNote) {
			noteToSave.id = this.note.id;
		}

		const mNote = { ...note, companyId, isForOutcome: !!isForOutcome };

		const formData: FormData = new FormData();
		formData.append('value', JSON.stringify({ ...mNote }));
		if (newAttachments?.attachments?.length) {
			newAttachments.attachments.forEach(x => {
				if (!(x instanceof File) && !!(x as File).name) {
					// is a Blob that has been given a name
					// need to send name as additional arg
					formData.append('files', x, (x as File).name);
				} else {
					formData.append('files', x);
				}
			});
		}
		try {
			const value = await this.userSession.webServiceHelper.callAsync<INote>(
				`${url}${updateExistingNote ? `/${note.id}` : ''}`,
				updateExistingNote ? 'PUT' : 'POST',
				formData
			);
			runInAction(() => {
				this.mSetRichContent(value);
			});
			return value;
		} finally {
			runInAction(() => {
				this.saving = false;
				// @ts-ignore
				this.savePromise = null;
			});
		}
	};
}
