import { UserSessionContext } from '../../extViewmodels';

export const FormFieldResolvers = {
	getAssociatedContactField: (context: UserSessionContext) => {
		return context.coffeeKeyFieldMappings?.deals.associatedContact ?? 'Associated Contact';
	},
	getDemoPerformDateField: (context: UserSessionContext) => {
		return context.coffeeKeyFieldMappings?.deals.demoPerformDate ?? 'Demo Perform Date';
	},
	getDemoPerformerField: (context: UserSessionContext) => {
		return context.coffeeKeyFieldMappings?.deals.demoPerformer ?? 'Levitate Closer Owner';
	},
	getMeetingSchedulerField: (context: UserSessionContext) => {
		return context.coffeeKeyFieldMappings?.deals.meetingScheduler ?? 'MeetingScheduler';
	},
};
