import { action, computed, observable } from 'mobx';
import { UserSessionContext, ViewModel, asApiError } from '../../../extViewmodels';
import { ILeadFollowUp } from '../queue';
import { ILeadSearchView, ILeadView, ILeadsSearchRequest, LeadAction } from './interfaces';
import { LeadViewModel } from './lead';

/**
 * The LeadsViewModel will load and hold the current lead that is being displayed. It should be used to control what
 * lead is shown at any given time, but not information about the specific lead itself.
 */
export class LeadsViewModel extends ViewModel {
	// @ts-ignore
	@observable private mLead: LeadViewModel = null;
	// @ts-ignore
	@observable private mAction: LeadAction;
	// @ts-ignore
	@observable.ref private lastSuccessfulRequest: ILeadsSearchRequest;
	// @ts-ignore
	@observable.ref private nextRequest: ILeadsSearchRequest;

	constructor(userSession?: UserSessionContext) {
		super(userSession);
	}

	@computed
	get action() {
		return this.mAction;
	}

	@computed
	get isBusy() {
		return this.busy;
	}

	@computed
	public get isSearching() {
		const leadsSearchRequest = this.nextRequest || this.lastSuccessfulRequest;
		if (leadsSearchRequest) {
			return !!leadsSearchRequest.searches && leadsSearchRequest.searches.some(x => !!x.value);
		}
		return false;
	}

	@computed
	get lead() {
		return this.mLead;
	}

	@action
	public getLeadById = async (id: string) => {
		if (this.busy) {
			return null;
		}

		this.busy = true;

		const result = await this.userSession.webServiceHelper.callWebServiceAsync<ILeadView>(
			this.composeApiUrl({ urlPath: `lead/${id}` }),
			'GET'
		);

		if (result.success) {
			// @ts-ignore
			this.mLead = new LeadViewModel(this.userSession, result.value);
			this.busy = false;
			return result.value;
		}

		this.busy = false;
		throw asApiError(result);
	};

	@action
	public getNext = async (params?: { ruleId?: string }) => {
		const ruleId = params?.ruleId;

		if (this.busy) {
			return null;
		}

		this.reset();
		this.busy = true;

		const result = await this.userSession.webServiceHelper.callWebServiceAsync<ILeadView>(
			this.composeApiUrl({ queryParams: { ruleId }, urlPath: 'lead' }),
			'GET'
		);

		if (result.success) {
			// @ts-ignore
			this.mLead = new LeadViewModel(this.userSession, result.value);
			this.busy = false;
			return result.value;
		}

		this.busy = false;
		if (result.systemCode !== 404) {
			throw asApiError(result);
		}

		return null;
	};

	@action
	public reloadLead = async () => {
		if (!this.busy) {
			this.busy = true;

			const result = await this.userSession.webServiceHelper.callWebServiceAsync<ILeadView>(
				this.composeApiUrl({ urlPath: `lead/${this.lead.company.id}` }),
				'GET'
			);

			if (result.success) {
				// @ts-ignore
				this.mLead = new LeadViewModel(this.userSession, result.value);
				this.busy = false;
			} else {
				this.busy = false;
				throw asApiError(result);
			}
		}
	};

	@action
	public reset = () => {
		// @ts-ignore
		this.mAction = null;
		this.busy = false;
		this.loading = false;
		// @ts-ignore
		this.mLead = null;
	};

	@action
	public setLeadFollowUp = (leadFollowUp: ILeadFollowUp) => {
		this.mLead.setFollowUp(leadFollowUp);
	};

	@action
	public setAction = (leadAction: LeadAction) => {
		this.mAction = leadAction;
	};
}

export class LeadSearchResultsViewModel extends ViewModel {
	@observable private mLeads: ILeadSearchView[] = [];
	private searchCounter = 0;

	constructor(userSession: UserSessionContext) {
		super(userSession);
	}

	@computed
	get leads() {
		return this.mLeads;
	}

	@action
	public reset = () => {
		this.busy = false;
		this.mLeads = [];
	};

	@action
	public search = async (query: string) => {
		this.busy = true;
		const thisRequest = this.searchCounter + 1;
		this.searchCounter += 1;
		const result = await this.userSession.webServiceHelper.callWebServiceAsync<ILeadSearchView[]>(
			this.composeApiUrl({ queryParams: { search: query }, urlPath: `lead/search` }),
			'GET'
		);

		if (this.searchCounter !== thisRequest) {
			return; // stale info
		}

		if (!result.success) {
			this.busy = false;
			throw asApiError(result);
		}

		this.busy = false;
		// @ts-ignore
		this.mLeads = result.value;
	};
}
